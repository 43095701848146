import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { GatsbyImage } from "gatsby-plugin-image";
import ContactPerson from "../../components/contactPerson";
import { deleteCookies } from "../../components/cookieConsent";

const Privacy = () => {
  const images = useStaticQuery(graphql`
    {
      volker: file(relativePath: { eq: "profiles/volker-schroedter.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Layout lang="en" langPath="nav.privacy.link">
      <Seo
        title="Monday Consulting GmbH – Privacy policy"
        description="Full details of how we handle data protection."
        keywords="Privacy policy, disclaimer, external links, Google Analytics, liability, copyright"
      />
      <div className="parallax-translate bg-shape-diamond" />
      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col-12">
            <h1 className="text-size-2 text-size-md-1">Privacy policy</h1>
          </div>
        </div>
      </div>
      <div className="container mt-4 mb-4 reveal">
        <div className="row">
          <div className="col-12">
            <p>
              Thank you for visiting our website www.monday-consulting.com. We
              appreciate your interest in our company, and in our products and
              services. While we perform thorough reviews before linking to
              third-party content, we are not liable for such content, since we
              are not responsible for the transfer of this information, and have
              neither chosen the recipients of the information transferred nor
              chosen or modified the information transferred.
            </p>
            <p>
              We take the protection of your personal data that is collected,
              processed and used in the context of your visit to our website
              very seriously, and all such activities are performed in
              compliance with applicable laws. Information about these laws is
              available from sites such as www.bfd.bund.de.
            </p>
            <p>
              This privacy policy explains what kinds of information we collect
              during your visit to our website and how this information is used.
            </p>

            <h2 className="h4">
              1. Collection and storage of personal data; nature and purpose of
              data usage
            </h2>
            <p>
              Every time a customer (or other visitor) accesses our website,
              information provided by the web browser used on your device (such
              as a PC, laptop, tablet, smartphone, etc.) is sent automatically
              to the server hosting our website. This information is temporarily
              stored in a record file known as a ‘log file’.
            </p>
            <p>
              Collection of the following data involves no action on your part
              and data is stored until automatic deletion:
            </p>
            <ul>
              <li>
                IP address of the computer making the request, device ID or
                unique device code, plus device type
              </li>
              <li>
                Name of the file accessed and volume of data transferred, plus
                date and time of access
              </li>
              <li>Message confirming successful access</li>
              <li>Domain making the request</li>
              <li>
                Description of the type of web browser being used and the
                operating system of your device (possibly), and the name of your
                access provider
              </li>
              <li>
                Your browser history data and your standard weblog information
              </li>
              <li>
                Location data, including location data from your mobile device.
                Please note that you can deactivate or otherwise configure the
                use of location services for most mobile devices in the settings
                for your mobile device.
              </li>
            </ul>
            <p>
              Our legitimate interest in data collection pursuant to point (f)
              of art. 6(1) of the EU GDPR is derived from the following
              purposes: ensuring error-free connectivity and ease of use for our
              website visitors, performing analyzes of system security and
              stability, as well as other administrative purposes.
            </p>
            <p>
              We will never use the data we collect for the purpose of
              attempting to identify you as a person.
            </p>

            <h2 className="h4">2. Sharing of personal data</h2>
            <p>
              Your data is not shared with third parties for any purposes other
              than those listed in the following sections.
            </p>
            <p>We share your data with third parties, only if</p>
            <ul>
              <li>
                you have given your express consent to this sharing (pursuant to
                point (a) of art. 6(1) of the EU GDPR);
              </li>
              <li>
                this sharing is necessary for managing our contractual
                relationship with you (point (b) of art. 6(1) of the EU GDPR);
              </li>
              <li>
                we are required by law to share this data (point (c) of art.
                6(1) of the EU GDPR); and/or
              </li>
              <li>
                this sharing is required in order to assert, exercise or defend
                legal claims and there is no reason to believe that you have an
                overriding legitimate interest in your data not being shared in
                this way (point (f) of art. 6(1) of the EU GDPR).
              </li>
            </ul>
            <p>
              However, the scope of the data transferred for any of these
              reasons is kept to the minimum necessary.
            </p>
            <p>
              The provisions of our privacy policy reflect applicable data
              protection legislation and your data is processed only within the
              Federal Republic of Germany and/or the European Union. No data is
              transferred into third countries and no such transfers are
              envisaged.
            </p>

            <h2 className="h4">3. Rights of the data subject</h2>
            <p>
              You are welcome to ask us for information about the personal data
              we have stored about you at any time (art. 15 of the EU GDPR). In
              particular, you may request information about: the purposes of our
              processing; the category of personal data; the categories of
              recipients to whom your data has been or will be disclosed; the
              planned period of storage; the existence of a right to
              rectification, erasure, restriction of processing or objection;
              the existence of a right to lodge a complaint; the origin of your
              data, if we did not collect your data originally; and the
              existence of an automated decision-making process, including
              profiling.
            </p>
            <p>
              You may also request the rectification of any errors in personal
              data collected about you or the completion of incomplete data
              collected about you (art. 16 of the EU GDPR).
            </p>
            <p>
              You also have the right to request us to restrict the processing
              of your personal data, assuming the necessary legal conditions
              have been met (art. 18 of the EU GDPR).
            </p>
            <p>
              You have the right to receive the personal data we have stored
              about you in a structured, commonly used and machine-readable
              format, or to request the transfer of the data to another
              controller (art. 20 of the EU GDPR).
            </p>
            <p>
              Moreover, you are also granted the right popularly known as the
              ‘right to be forgotten’: this means you can ask us to erase your
              personal data, assuming the necessary legal conditions have been
              met (art. 17 of the EU GDPR).
            </p>
            <p>
              Independently of this right, we automatically erase your personal
              data once the purpose for which we collected the data no longer
              applies or if data processing was performed unlawfully.
            </p>
            <p>
              Pursuant to art. 7(3) of the EU GDPR, you have the right to
              withdraw consent you gave to us at some earlier point in time. If
              you withdraw your consent, we are prohibited in the future from
              performing the data processing that was based on this consent.
            </p>
            <p>
              In cases where you are granted a legal right to object, you may
              also object to the processing of your personal data at any time.
              In the event of a withdrawal of consent on your part, we will also
              automatically erase your personal data (art. 21 of the EU GDPR).
            </p>
            <p>
              If you wish to exercise your right to withdraw consent or your
              right to object, simply email us at:
              <a href="mailto:datenschutz@monday-consulting.com">
                datenschutz@monday-consulting.com
              </a>
              .
            </p>
            <p>
              In the event of a breach of data protection law, you have the
              right to lodge a complaint at a supervisory authority, pursuant to
              art. 77 of the EU GDPR.
            </p>

            <h2 className="h4">4. Duration of data storage</h2>
            <p>
              We store the data we have collected for as long as is required in
              order to fulfil the contracts we have received or until you
              exercise your right to erasure or to data portability (transfer to
              another controller), whichever is the sooner.
            </p>

            <h2 className="h4">5. Cookies</h2>
            <button
              className="btn ml-2 my-1 my-md-2"
              onClick={() => {
                deleteCookies();
                window.location.reload();
              }}
            >
              Cookie settings
            </button>
            <p>
              We use cookies on our website. These cookies are small text files
              that your browser creates automatically and which are stored on
              your user device when you visit our website. Inside the cookie,
              information is stored that corresponds to the specific user device
              that is being used. However, this does not mean that we gain
              direct knowledge of your identity. Our use of cookies is primarily
              intended to make your visit to our website more convenient and
              comfortable.
            </p>
            <p>
              We also use cookies to record and analyze statistics about website
              usage, and to optimise our internet presence to make it easier for
              you to use. If you visit our website again, these cookies enable
              us to recognise the fact that you have visited our website
              previously. These cookies are deleted automatically after a
              specified period of time. The data processed by cookies is
              required for the stated purposes to safeguard our legitimate
              interests and those of third parties pursuant to point (f) of art.
              6(1) of the EU GDPR. Most browsers will accept cookies
              automatically. If you do not want us to recognise information
              about your user device on a second visit, please configure your
              web browser so that it deletes cookies from your device’s storage,
              blocks all cookies or warns you before a cookie is stored on your
              device. Please consult the help and support area of your web
              browser to find out how you can block and delete cookies. Here,
              you will also find instructions about how to search for the file
              or folder in which cookies are stored. Whatever action you take,
              please note that deactivating all cookies can lead to a situation
              where you are unable to use all of the functionality offered by
              our website.
            </p>
            <p>
              The provisioning of our online platform makes use of both Java
              applets and JavaScript. If you do not want to make use of these
              utility programs or active content for security reasons, you
              should deactivate the corresponding setting in your web browser.
            </p>

            <h2 className="h4">6. Online marketing / analysis tools</h2>
            <p>
              Our tracking measures are performed pursuant to point (f) of art.
              6(1) of the EU GDPR and section 15(3) of the German Teleservices
              Act (TMG). The tracking measures we have put into place are
              intended to ensure that the website design meets user needs and
              can be continuously optimized to this end. We also use the
              tracking measures to record statistics about the usage of our
              website, and for the purpose of analysing and optimizing our
              internet presence. These interests are legitimate pursuant to the
              laws and regulations mentioned above.
            </p>
            <h3 className="h5">Google Analytics</h3>
            <p>
              For the purposes of ensuring our websites meet user needs and can
              be continuously optimized, we use Google Analytics, a web analysis
              service from Google Inc. (www.google.com/intl/en/about/) (1600
              Amphitheatre Parkway, Mountain View, CA 94043, USA; ‘Google’). In
              the context of this usage, pseudonymised user profiles are
              created, as well as cookies (see also section 5). The information
              generated by this cookie about your use of this website, such as
              the web browser type/version, the operating system you use, the
              referrer URL (the previous page you were viewing), the hostname
              (IP address) of the computer being used for access, and the time
              of the server request is transmitted to a Google server in the USA
              and stored there. This information is used in order to analyze the
              use of the website, to provide consolidated reports about website
              activity, and to provide other services associated with website
              usage and internet usage for the purposes of conducting market
              research, and to ensure that the design of this website meets user
              needs. These purposes also grant us a legitimate interest in data
              processing. The legal basis for the use of Google Analytics is
              section 15(3) of the TMG in conjunction with point (f) of art.
              6(1) of the EU GDPR.
            </p>
            <p>
              This information may also be shared with third parties where this
              is required by law or in cases where third parties act as
              processors for this data. Your IP address will never be aggregated
              with other data held by Google. All IP addresses are anonymised to
              ensure that association is not possible (known as ‘IP masking’).
            </p>
            <p>
              The data we transmit, which may be linked with cookies, user codes
              (e.g. user IDs) or advertising IDs, is deleted automatically after
              14 months. The deletion of data whose retention period has expired
              is performed automatically once a month.
            </p>
            <p>
              For Google’s terms and conditions of use and the privacy policy
              for Google Analytics, please visit
              marketingplatform.google.com/about/&shy;analytics/terms/gb/.
            </p>
            <p>
              By configuring the appropriate settings in your browser
              application, you can prevent the installation and storage of
              cookies. We must advise you, however, that blocking cookies may
              mean that you are unable to utilize all of the features that are
              offered by this website.
            </p>
            <p>
              In addition, you can also prevent the recording and transmission
              of data generated by the cookie in relation to your use of the
              website (including your IP address), and the processing of this
              data by Google, by downloading and installing the browser plugin
              available from the following link:
              tools.google.com/dlpage/gaoptout?hl=en.
            </p>
            <p>
              As an alternative to the browser add-on, particularly when
              browsing on mobile devices, you can also prevent data collection
              by Google Analytics by using the following link. This will set an
              opt-out cookie that prevents your data being collected in the
              future when you visit this website. Deactivate Google Analytics.
              The opt-out cookie is set only in this browser and only for our
              website, and is stored on your device. If you delete the cookies
              in this browser, you will need to set the opt-out cookie again.
            </p>
            <h3 className="h5">Google Adwords / conversion tracking</h3>
            <p>
              We also use the online advertising software Google AdWords as part
              of Google AdWords conversion tracking. Google conversion tracking
              is an analysis service made available by Google. If you click on
              an advert served by Google, a cookie for conversion tracking is
              stored on your user device. These cookies, which become invalid
              after 30 days, do not contain any personal data and cannot
              therefore be used to identify you personally. If you visit certain
              internet pages on our website and the cookie has not yet expired,
              Google and Monday Consulting can both identify that you have
              clicked on the advert and have been redirected to this page. Each
              Google AdWords customer is assigned a different cookie.
              Accordingly, there is no possibility of cookies being tracked
              across websites from different AdWords customers. The information
              that is collected with the help of the conversion cookie is used
              to generate conversion statistics for AdWords customers who have
              decided to make use of conversion tracking. The service provides
              customers with the total number of users who have clicked on their
              advert and been redirected to a webpage with an embedded
              conversion tracking tag. Customers do not receive any information
              with which they could personally identify users, however. These
              purposes also grant us a legitimate interest in data processing.
              The legal basis for the use of Google AdWords/conversion tracking
              is section 15(3) of the TMG in conjunction with point (f) of art.
              6(1) of the EU GDPR.
            </p>
            <p>
              If you do not want to be tracked, you can withdraw your consent to
              this usage by preventing the installation of the cookie from the
              ‘googleadservices.com’ domain. You do this by making a
              corresponding setting in your web browser software (deactivation
              option). You will then no longer be included in the conversion
              tracking statistics. For further information, as well as the
              privacy policy published by Google, please visit:
              policies.google.com/technologies/ads ,
              policies.google.com/privacy.
            </p>
            <h3 className="h5">Google Adsense</h3>
            <p>
              This website uses Google Adsense, a service to embed
              advertisements that is provided by Google, Inc. (‘Google’). Google
              Adsense uses ‘cookies’, which are text files that are stored on
              your computer and that enable the analysis of your use of the
              website. Google AdSense also uses ‘web beacons’ (invisible
              images). These web beacons can be used to report on information
              such as visitor traffic to this website. The information generated
              by cookies and web beacons about your use of this website
              (including your IP address) and the delivery of advertising
              formats is transferred to a Google server in the USA and stored
              there. Google may choose to share this information with its
              business partners. Google will not aggregate your IP address with
              other data stored about you, however. You can prevent the
              installation of the cookie by configuring an appropriate setting
              in your browser software. We would remind you, however, that if
              you do so, you may no longer be able to use the full range of
              functionality as offered by this website. By using this website,
              you give your consent to the processing of the data collected
              about your visit by Google in the manner described and for the
              aforementioned purpose.
            </p>
            <h3 className="h5">Google Remarketing</h3>
            <p>
              This website uses Google Remarketing, an advertising service from
              Google Inc. (‘Google’). Third parties, including Google, serve
              adverts to websites on the internet. Third parties, including
              Google, use cookies to serve adverts on the basis of previous
              visits by the same user to this website. Users can deactivate
              Google’s use of cookies by accessing the web page to deactivate
              advertising, which is located at www.google.com/privacy_ads.html.
              Alternatively, users can deactivate the use of cookies by third
              parties by accessing the deactivation page provided by the Network
              Advertising Initiative, located at
              www.networkadvertising.org/&shy;managing/opt_out.asp.
            </p>

            <h2 className="h4">7. Data security</h2>
            <p>
              We have taken all of the technical and organizational security
              precautions necessary to store your personal data so that it is
              not accessible to third parties or the general public. If you wish
              to contact us by email, please be advised that confidentiality
              cannot be guaranteed in all circumstances for the information that
              is exchanged via this particular communication channel. We
              therefore always recommend using the postal service if you need to
              send us confidential information.
            </p>
            <h2 className="h4">
              8. Currency of this privacy policy and future changes
            </h2>
            <p>This privacy policy is current and valid.</p>
            <p>
              We may need to change this privacy policy as a result of the
              development of our website and our products and services, or as a
              result of changes to legislation or because of local laws and
              regulations. Our current and valid privacy policy can always be
              found on our website at
              www.monday-consulting.com/en/privacy-policy/ for you to view or
              print out as needed.
            </p>
            <h3 className="h5">Name and contact details of the controller</h3>
            <p>This privacy policy applies for data processing conducted by:</p>
            <p>
              Monday Consulting GmbH
              <br />
              Breite Straße 61
              <br />
              22767 Hamburg
              <br />
              Telefon: +49 40 22 86 842 0<br />
              E-Mail:{" "}
              <a href="mailto:contact@monday-consulting.com">
                contact@monday-consulting.com
              </a>
              <br />
            </p>
            <h3 className="h5">Data Protection Officer:</h3>
            <p>
              D&C Datenschutz und Consulting
              <br />
              Dirk Borbe
              <br />
              Belemannweg 15
              <br />
              22419 Hamburg
              <br />
              Telefon: +49 162 58 17 253
              <br />
              E-Mail:{" "}
              <a href="mailto:datenschutz@monday-consulting.com">
                datenschutz@monday-consulting.com
              </a>
            </p>
            <p>Stand: 01.04.2021</p>
          </div>
        </div>
      </div>
      <ContactPerson
        headline="We take your privacy very seriously."
        text="Please do not hesitate to contact us if you have any questions about our handling of data protection and privacy."
        contactEmail="datenschutz@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Volker Schrödter, Chief Executive"
      >
        <GatsbyImage
          image={images.volker.childImageSharp.gatsbyImageData}
          alt="Volker Schrödter"
        />
      </ContactPerson>
    </Layout>
  );
};

export default Privacy;
